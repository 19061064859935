//
// Environment
//


//
// Config values
//

$platform-name: 'womensresourcecentre';


//
// Colours
//

$brand-primary: #872183;
$brand-secondary: #339CB6;
$brand-orange: #EE7219;
$brand-green: #7AB929;

$donate-colour: $brand-orange;

// Create greyscale
$black:             #000 !default;
$grey-dark:         #292b2c !default;
$grey:              #464a4c !default;
$grey-light:        #636c72 !default;
$grey-lighter:      #eceeef !default;
$grey-lightest:     #f7f7f9 !default;
$white:             #fff !default;





//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 960px;


// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
) !default;


// Spacers
$spacer: 1rem !default;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates


//
// Transitions
//



//
// Logo
//

$logo: 'logo.svg';
$logo-width: 260px;
$logo-height: 120px;


//
// Typography
//

$font-family-base: 'Nunito', Arial, sans-serif;
$font-size-base: 18px;

$font-weight-normal: normal !default;
$font-weight-bold: 800;

$font-size-h1: 2.5em !default;
$font-size-h2: 28px;
$font-size-h3: 26px;
$font-size-h4: 1.5em !default;
$font-size-h5: 1.25em !default;
$font-size-h6: 18px;

$headings-margin-top: 1.5em !default; // This will only affect headings within post content


// Links

// Icon font


//
// Buttons
//

$btn-padding-x: 1.5em !default;
$btn-padding-y: .6em !default;
$btn-font-weight: $font-weight-bold;
$btn-border-radius: 5px;
$btn-background-colour: $brand-secondary;
$btn-text-transform: uppercase;

// Donate button
$donate-btn-colour: white;

//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//

// Social network colours - use these by changing $social-icons-use-brand-colours to true
$social-icons-background-colour: $brand-secondary; // A colour, transparent, or 'brand'
$social-icons-colour: white; // A colour, or 'brand'
// $social-icons-hover-colour: brand; // A colour, or 'brand'

// Header specific

// Footer specific social icons
$social-icons-footer-background-colour: $brand-primary; // A colour, transparent or 'brand'


//
// Share this page
//



//
// Form elements
//

$input-padding-y: .375rem !default;
$input-padding-x: .75rem !default;


// Field groups


//
// Tables
//



//
// Card
//

$card-border: 0;
$card-footer-background-colour: transparent;

// Card text overlay

// Card text over

// Card side by side

// Card hover state
$card-hover-image-opacity: .7 !default;
$card-hover-image-scale: 1.1;


//
// Menu admin
//

$menu-admin-background-colour: transparent;
$menu-admin-absolute: true;

//
// Header
//


// Header content

// Tagline

// Main call to action

// Search

// Search - input
$header-search-input-margin-right: -43px;
$header-search-input-padding: $input-padding-y 43px $input-padding-y $input-padding-x;

// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: $grey;

// Social icons

// Colours set above (search for '$social-icons-header')


//
// Sticky header
//


//
// Navigation
//
$nav-background-colour: $brand-primary;


// Top level items
$nav-top-level-item-padding: 20px 20px;
$nav-top-level-item-colour: white;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-chevrons-enabled: true;

// Submenus
$nav-submenu-background-colour: white;
$nav-submenu-box-shadow: 0 20px 20px rgba(black, 0.05);

// Burger button
$burger-btn-bar-breakpoint: map-get($breakpoints, sm); // What viewport width do you want the burger button to pop under the header full width?

// Nav normal
$nav-normal-margin-bottom: 0;
$nav-normal-align-items: center; // left, center, right
$nav-normal-max-width: 100%; // $container-max-width


//
// Specifically when no banner present and you have gone for $header-absolute: true
//



//
// Carousel
//
$carousel-max-width: 100%;
$carousel-image-overlay: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 25%, rgba(0, 0, 0, 0.4) 35%, rgba(0, 0, 0, 0) 50%); // Add a colour value or gradient here
$carousel-details-background-colour: transparent;
$carousel-details-max-width: 500px;
$carousel-contents-max-width: $container-max-width; // How wide do you want .carouselDetailWrapper?
$carousel-details-margin-y: 2rem;
$carousel-heading-colour: white;
$carousel-summary-colour: white;



// Carousel controls (left-right buttons)
$carousel-controls-position-x: split; // split, left or right
$carousel-controls-margin-y: 3rem;
$carousel-controls-button-border-radius: 5px;
$carousel-controls-margin-x: 1rem;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//

$home-intro-background-image: 'background.png';
$home-intro-background-image-opacity: 1;
$home-intro-background-colour: white;
$home-intro-colour: white;
$home-intro-margin-top: 0;


//
// Home features
//
$home-features-cards-entry-animation-prefab: 1; // See https://fresco-docs.netlify.com/#/cards/entry-animations
$home-features-padding-top: 0;
$home-features-background-colour: transparent;



//
// Impact stats
//
$impact-stats-background-image: 'impact.jpg';
$impact-stats-background-colour: $brand-secondary;
$impact-stats-heading-margin-bottom: $spacer * 1;
$impact-stats-padding-y: $spacer * 6;
$impact-stats-background-image-opacity: 0.7;


// Heading
$impact-stats-heading-font-size: $font-size-h3;

// Individual stat

// Figure

// Summary


//
// Home feeds
//


// Feeds title
$feeds-title-font-size: $font-size-h3;

// Feed item

// Event feed items
$feed-item-event-location-enabled: false;

// Twitter feed
$home-feed-twitter-enabled: true;
// $home-feed-twitter-card-padding: 10px;

//
// Footer
//

$footer-boxes-count: 3;
$footer-prefab: 7;
$footer-background-colour: $brand-secondary;

// Footer admin links
$footer-admin-basket-link-enabled: false;
$footer-admin-manager-link-enabled: false;
$footer-admin-my-details-link-enabled: false;

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-heading-font-size: $font-size-h4;
$newsletter-heading-text-align: left; // left, center, right
$newsletter-heading-colour: white;
$newsletter-use-placeholders: true; // Turn on to use placeholders in the inputs rather than labels
$newsletter-button-background-colour: $brand-primary;

// Newsletter hero - full width form above rest of footer


//
// Context container
//


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//

$header-text-margin-top: $spacer;


//
// Page title
//

$page-title-margin-top: $spacer * 2;


//
// Sidebar
//

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts
$sidebar-layout: above;

// Associated lists

//
// Listing
//


// Listed post settings


//
// Breadcrumb
//



//
// Blockquote
//

$blockquote-background-colour: transparent;
$blockquote-colour: $brand-secondary;
$blockquote-font-size: 2em; // Em unit will cascade to children
$blockquote-text-align: left; // left, center, right


//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//

$donation-form-frequency-tabs: true;

// Donation amounts


//
// Quick giving panel
//
$quick-giving-background-colour: transparent;
$quick-giving-border-radius: 5px;
$quick-giving-donation-amount-background-colour: rgba(white, 0.2);



//
// Home quick giving panel
//
$home-quick-giving-background-colour: white;
$home-quick-giving-max-width: $container-max-width;
$home-quick-giving-padding-y: $spacer * 2;
$home-quick-giving-heading-colour: white;
$home-quick-giving-donation-amount-background-colour: rgba(white, 0.2);
$home-quick-giving-donate-btn-background-colour: $brand-orange;
$home-quick-giving-background-image: 'background.png';
$home-quick-giving-background-image-opacity: 1;


// Adjacent homepage quick giving

// Overlay homepage quick giving


//
// Totalisers
//



//
// Comments
//



//
// Blog details
//

$blog-details-enabled: false;

//
// FAQs
//



//
// Shop
//

$shop-enabled: true !default; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)

// Departments list

// Product-price

// Product post


//
// Subsite
//


// Subsite logo

// Subsite nav


//
// Cookie consent
//

$banner-min-height: 0;